import { isMobile, isMobileOnly } from 'mobile-device-detect'
import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'
import gsap from 'gsap'

const $ = window.jQuery
$(function() {
  // =========================
  // gHeader関係
  // =========================
  // Hamburger Menu
  document.querySelectorAll('[data-modal-button]')
    .forEach((button) => {
      button.addEventListener('click', function (e) {
        if (this !== e.target){
          return
        }
        this.classList.toggle('open');
        const id = this.getAttribute('data-modal-button')
        // 一つしかないはずなのであえてquerySelectorAllは使わない
        const modal = document.querySelector(`[data-modal="${id}"]`)
        modal.classList.toggle('show');
        // すでに開いているモーダルがある場合、ちょっと遅れて消す
        const prevs = Array.from(document.querySelectorAll('[data-modal].show'))
          .filter((m) => m.getAttribute('data-modal') !== id)
        if(prevs.length) {
          prevs.forEach((_modal) => {
            _modal.classList.remove('show');
            const _id = _modal.getAttribute('data-modal');
            document.querySelector(`[data-modal-button="${_id}"]`).classList.remove('open');
          })
        } else {
          document.body.classList.toggle('noscroll');
        }
      });
    })
  // gHeaderの背景色をつける
  const setHeaderBG = (selector) => {
    new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          document.querySelector('#gHeader').classList.remove('with-bg');
        } else {
          document.querySelector('#gHeader').classList.add('with-bg');
        }
      });
    }).observe(document.querySelector(selector));
  }
  if (window.location.pathname === '/') {
    setHeaderBG('#mv')
  }
  else if (window.location.pathname === '/archives/') {
    setHeaderBG('.fv')
  }
  else {
    document.querySelector('#gHeader').classList.add('with-bg')
  }
  // スクロールに応じたgHeaderの表示・非表示
  let wScrollBefore = 0
  const threshold = 3
  window.addEventListener('scroll', function() {
    const pageYOffset = Math.max(0, window.scrollY) // iOSの慣性スクロールで負の値になってしまわないように
    const wScrollDiff = wScrollBefore - pageYOffset
    if (threshold <= Math.abs(wScrollDiff)) {
      const header = document.querySelector('#gHeader')
      if (0 <= wScrollDiff) {
        header.classList.remove('hidden')
      } else {
        header.classList.add('hidden')
      }
    }
    wScrollBefore = pageYOffset
  })

  // =========================
  // その他
  // =========================

  // 一瞬ホバーしただけでもhoverスタイルをつけてカーソルが通った時に印象的にするための処理
  // 使い方：
  // - 要素に [data-insensitive-hover] を付与。
  // - 値にはtransition-durationの値を入れる。
  // - 値が空なら、その要素のtransition-durationを自動取得。 ※ ただし、この自動取得がうまくいかなかったためにdata属性の値で受け渡して対応しているので注意。
  // - その要素のスタイルに下記のような方法で、hoverスタイルをつける
  // &:hover,
  // &.insensitive-hover-locked {
  //   background-color: #000;
  // }
  $('[data-insensitive-hover]').each(function() {
    const me = $(this)
    // もし、data-insensitive-hoverが空なら、transition-durationを取得
    let transitionDurationMs = me.attr('data-insensitive-hover')
    if (transitionDurationMs === '') {
      transitionDurationMs = getComputedStyle(this).transitionDuration.replace('s', '') * 1000
    }
    let timer
    me.on('mouseenter', () => {
      // transition-durationの完了までは、クラスをスタイルをつけたままにする
      me.addClass('insensitive-hover-locked')
      clearTimeout(timer)
      timer = setTimeout(() => {
        me.removeClass('insensitive-hover-locked')
      }, transitionDurationMs)
    })
  })

  // 動画自動再生
  $('.movie').each(function() {
    const el = $(this)
    const src = el.attr(`data-source-${isMobileOnly ? 'sp' : 'pc'}`)
    var video = $(`
    <div class="videoWrap">
      <video class="hidden" preload="auto" autoload autoplay loop muted playsinline webkit-playsinline>
        <source src="${src}" type="video/mp4" />
      </video>
    </div>
    `)
    video.find('video').on('canplay', function() {
      video.find('video').removeClass('hidden')
      if (el.attr('data-remove-ready-after-canplay') === 'true') {
        $('body').removeClass('ready')
      }
    })
    video.appendTo(el)
  })

  // 記事中にあるデフォルトのギャラリーをslickに変換
  $('.wp-block-gallery').each(function (index) {
    let carousel = $(`<div class="swiper-wrapper" id="carousel${index + 1}"></div>`)
    $(this).find('figure').each(function() {
      let caption = $(this).find('figcaption').text()
      if (caption.length) {
        caption = `<figcaption>${caption}</figcaption>`
      }
      let img = $(this).find('img').attr('srcset')
      img = img.split(',')
      img = img[img.length - 1].trim()
      img = img.split(' ')[0]
      carousel.append(`<figure class="swiper-slide"><img src="${img}" />${caption}</figure>`)
    })
    $('<div class="swiper"></div>').append(carousel).append('<div class="swiper-pagination"></div>').insertAfter(this)
    $(this).remove()
  })
  new Swiper('.swiper', {
    modules: [Navigation, Pagination],
    speed: 1000,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true
    },
    pagination: {
      el: `.swiper-pagination`,
      type: 'bullets',
      clickable: true,
    },
    loop: true
  })

  // ページトップ
  $(document).on('click', '.pageTop', (e) => {
    e.preventDefault()
    // ページトップへスクロール
    window.scrollTo({top: 0, behavior: 'smooth'});
  })

  // #cursorをカーソルに追従
  const cursor = document.querySelector('#cursor')
  if (isMobile) {
    // モバイルの場合はカーソルを削除
    cursor.remove()
  } else {
    document.addEventListener('mousemove', (e) => {
      gsap.to(cursor, {
        x: e.clientX,
        y: e.clientY,
        duration: 1,
        ease: "power2.out"
      });
      cursor.classList.remove('hidden')
    })
    document.addEventListener('mouseleave', () => {
      cursor.classList.add('hidden')
    })
    $(document)
      .on('mouseenter', 'a[href]', (e) => {
        const anchor = $(e.target).closest('a')
        let cursorText = anchor.attr('data-cursor-text')
        if (typeof cursorText === 'undefined') {
          if (anchor.attr('target') === '_blank') {
            cursorText = 'Open'
          } else {
            cursorText = 'More'
          }
        }
        cursor.querySelector('.text').textContent = cursorText
        cursor.classList.add('active')
      })
      .on('mouseleave', 'a[href]', () => {
        cursor.classList.remove('active')
      })
  }
})
